<template>
    <b-modal id="ActivatePromocode" size="lg" title="Активируйте промокод" v-model="show"
             :hide-footer="true" class="promocode-modal">
        <div class="col-12 px-0 auth-page">
            <b-row class="no-gutters">
                <b-col class="px-0 mx-0 mx-auto">
                    <b-form class="form-horizontal auth-form px-0 px-lg-3 mx-0" @submit.prevent="activatePromocode"
                            autocomplete="off">

                        <b-form-group class="mb-4">
                            <div class="col-4 px-0 d-inline-block text-left text-md-right">
                                <label class="col-form-label white-color">Введите промокод</label>
                            </div>
                            <div class="col-8 d-inline-block">
                                <b-form-input v-model="payload.promocode" required autocomplete="off"
                                              class="border-0"></b-form-input>
                            </div>
                        </b-form-group>

                        <b-form-group>
                            <div class="col-4 px-0 d-inline-block vertical-top ttext-left text-md-right">
                                <label class="col-form-label white-color">Введите символы</label>
                                <captcha ref="captcha_promocode_activation" :captcha_key="'promocode_activation'"
                                         class="float-center float-md-right"></captcha>
                            </div>
                            <div class="col-8 d-inline-block">
                                <b-form-input v-model="payload.captcha" required class="border-0"></b-form-input>
                                <span class="white-color d-none d-md-block captcha-hint">Введите символы которые видите на картинке</span>
                            </div>
                        </b-form-group>

                        <alert :message="response"></alert>
                        <div class="clearfix"></div>

                        <div class="form-group row mt-4 mr-1 justify-content-end">
                            <div class="col-12 col-md-6">
                                <button class="btn btn-orange w-100" type="submit" name="submit"
                                        :disabled="isSubmitting">
                                    Активировать
                                </button>
                            </div>
                        </div>
                    </b-form>

                </b-col>
            </b-row>
        </div>
    </b-modal>
</template>

<script>
    import Alert from '@/components/Alert'
    import Captcha from '@/components/Captcha'

    export default {
        name: 'ActivatePromocode',
        data() {
            return {
                show: false,
                response: null,
                isSubmitting: false,
                payload: {
                    promocode: '',
                    captcha: ''
                }
            }
        },
        components: {
            Captcha,
            Alert
        },
        methods: {
            activatePromocode() {
                this.response = {status: 'loading'}
                this.isSubmitting = true
                this.axios.post(
                    this.$_config.baseUrl + '/Api/ActivateMyPromocode/',
                    `promocode=${this.payload.promocode}&promocode_activation_captcha=${this.payload.captcha}`,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.response = data
                    this.isSubmitting = false
                    this.payload.promocode = ''
                    this.payload.captcha = ''
                    if (data.status !== 'error') {
                        setTimeout(() => {
                            this.show = false
                        }, 3000)
                    }
                    this.$refs.captcha_promocode_activation.updateCaptcha()
                }).catch(err => {
                    if (err) {
                        this.$refs.captcha_promocode_activation.updateCaptcha()
                        this.response = {status: 'error', msg: err.msg}
                        this.isSubmitting = false
                    }
                })
            }
        }
    }
</script>

