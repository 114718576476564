<template>
    <div class="row">
        <div class="col-10 col-md-8 mx-auto px-0">
            <img :src="captchaUrl" class="captcha-img w-100" alt="captcha">
        </div>
        <div class="col-10 col-md-8 mx-auto px-0">
            <a @click.prevent="updateCaptcha"
               class="mt-1 w-100 btn btn-secondary btn-sm white-color border-0 refresh-btn">
                <i class="far fa-redo"></i>
                <span class="refresh-text"> обновить</span>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['captcha_key'],
        name: 'Captcha',
        data() {
            return {
                random: Math.random()
            }
        },
        computed: {
            captchaUrl() {
                return `${this.$_config.captchaUrl}/${this.captcha_key}?${this.random}`
            }
        },
        methods: {
            updateCaptcha() {
                this.random = Math.random()
            }
        }
    }
</script>

<style scoped>
    .refresh-btn {
        background-color: #2f7ab7;
        cursor: pointer;
        border-radius: 20px;
    }

    @media (min-width: 320px) and (max-width: 1024px) {
        .refresh-text {
            display: none;
        }
    }
</style>
