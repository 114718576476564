<template>
    <div class="messages-container col-12 mt-3 mt-lg-5 mx-auto px-0">
        <b-list-group class="col-12 mx-auto pr-0" v-if="messages.length">
            <b-list-group-item v-for="(message, idx) in messages" :key="idx" class="message-item mb-4 white-color">
                <b-row>
                    <b-col cols="12" class="message-text-container px-1" v-html="message.message"></b-col>
                    <div class="msg-time col-12 text-right mb-3">
                        <span>{{message.time}}</span>
                    </div>
                    <button v-if="!message.viewed"
                            class="btn btn-sm btn-custom markAsReadBtn orange-color position-absolute pr-3"
                            :ref="'markAsRead['+message.id+']'"
                            @click.prevent="()=>{markAsRead(message.id)}"
                            :disabled="markAsReads === message.id">
                        Отметить как прочитанное
                    </button>
                </b-row>
            </b-list-group-item>
        </b-list-group>
        <div class="text-center" v-else>
            <span class="gray-color">У вас пока нет сообщений</span>
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                message: {},
                markAsReads: null
            }
        },
        computed: {
            messages() {
                if (this.$store.getters['getMessages'] != null) {
                    let msgs = Object.values(this.$store.getters['getMessages'])
                    return msgs.reverse()
                }
                return {}
            },
            userData() {
                return this.$store.getters['userData']
            }
        },
        methods: {
            markAsRead(id) {
                this.markAsReads = id
                this.axios.get(
                    this.$_config.baseUrl + '/Api/MessageViewed/' + id
                ).then(() => {
                    this.markAsReads = null
                    this.$store.dispatch('fetchMessages')
                }).catch(err => {
                    if (err) {
                        this.$store.dispatch('fetchMessages')
                    }
                    this.markAsReads = null
                })
            }
        }
    }
</script>

<style>
    .message-item {
        background-color: rgba(204, 234, 255, 0.1) !important;
        border: 1px solid #132835 !important;
        border-radius: 10px !important;
    }
</style>

<style scoped>
    .msg-time {
        font-size: 12px;
    }

    .markAsReadBtn {
        right: 0;
        bottom: 0;
    }
</style>
