<template>
    <div class="bonus-container py-4 col-12 col-lg-11 mx-auto">
        <div class="my-4">
            <h1 class="site-title text-center page-title white-color">Уровни игроков</h1>
        </div>
        <div class="col-12 px-0" v-if="levels">
            <table class="table table-stripped table-secondary text-center levels-table">
                <thead>
                    <tr>
                        <th>Уровень</th>
                        <!-- <th>Кэшбэк</th> -->
                        <th class="d-none d-lg-table-cell">Доступные бонусы</th>
                        <th class="d-none d-lg-table-cell">Акции</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(level, idx) in levels" :key="idx" :class="{'active':userLevel === level.level}">
                        <td>
                            <img :src="imgUrl(level.icon)" alt="" class="level-img">
                            <p class="text-center mt-2">{{level.title}}</p>
                            <div class="level-progressbar" v-if="uDepSum && userLevel === level.level">
                                <b-progress :value="uDepSum" :max="level.max_border" animated>
                                </b-progress>
                                <span class="hint-text">{{reachProgress(level.max_border).levelPartReachedUnRounded}} Очков из 100</span>
                            </div>
                        </td>
                        <!-- <td>{{level.cashback}} %
                          <div class="d-lg-none">
                            <button class="btn btn-orange mt-2 px-3" @click.prevent="showLevelInfo(level.id)">Подробнее</button>

                            <div class="col-12 px-0 ">
                              <b-modal :id="'levelInfoModal_'+level.id"
                                       ref="'levelInfoModal_'+level.id"
                                       size="lg"
                                       modal-class="main-modal main-block"
                                       header-class="text-white border-0"
                                       header-close-variant="orange"
                                       title-class="text-center underlined-muted-title"
                                       body-class="pt-0"
                                       :hide-header="true"
                                       :hide-footer="true">
                                <h3 class="text-center my-4 text-white">Информация об уровне</h3>
                                <div class="row mx-0 message-text-container text-white px-0 px-lg-3 py-3 mb-1">
                                  <div class="col-12 mb-4 px-0" v-if="level.bonuses">
                                    <h5 class="text-center">Бонусы</h5>
                                    <p class="text-white" v-html="level.bonuses">  </p>
                                  </div>
                                  <div class="col-12 px-0" v-if="level.promotions">
                                    <h5 class="text-center">Акции</h5>
                                    <p class="text-white" v-html="level.promotions">  </p>
                                  </div>
                                </div>

                                <div class="col-12 px-0 text-right pb-5 pb-lg-0">
                                  <div class="d-inline-block">
                                    <button class="btn btn-danger font-weight-bold" @click="$bvModal.hide('levelInfoModal_'+level.id)">
                                      Закрыть
                                    </button>
                                  </div>
                                </div>
                              </b-modal>
                            </div>

                          </div>
                        </td> -->
                        <td class="d-none d-lg-table-cell" v-html="level.bonuses"></td>
                        <td class="d-none d-lg-table-cell" v-html="level.promotions"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "UserLevels",
    computed: {
      levels () {
        return this.$store.getters.clientsLevel
      },
      uDepSum () {
        return this.$store.getters.uDepSum
      },
      userLevel () {
        const userData = this.$store.getters.userData
        if(userData) {
          return userData.level
        }
        return false
      },

    },
    methods: {
      showLevelInfo(levelId) {
        return this.$root.$emit('bv::show::modal', 'levelInfoModal_'+levelId)
      },
      reachProgress ($maxBorder) {
        let levelPartReached = (this.uDepSum/$maxBorder)*100;
        const levelPartReachedUnRounded = (levelPartReached).toFixed(4);
        levelPartReached = Math.round(levelPartReached);
        return {
          levelPartReached, levelPartReachedUnRounded: parseFloat(levelPartReachedUnRounded)
        }
      },
      imgUrl (path) {
        if(window.origin.indexOf('lentaslot') === -1) {
          return 'https://lentaslot.com' + path
        }
        return path
      }
    }

}
</script>

<style scoped>
    .levels-table {
        background-color: unset;
        color: white;
    }
    .levels-table th {
        color: #f6d307d9;
    }
    td{
        vertical-align:middle !important;
        position: relative;
    }
    .level-img{
        width: 110px;
        border-radius: 10px;
    }
    tr.active{
        box-shadow: 0 0 15px 5px #ffffff7d;
    }
    tr.active td:nth-child(2):before{
        display: block;
        content: 'Ваш Уровень';
        font-size: 16px;
        color: rgba(246, 211, 7, .85);
    }
</style>
