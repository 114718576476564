<template>
    <div>
        <b-modal id="superomaticModal" title="Выберите денминацию" size="md"
                 :hide-footer="true"
                 :no-close-on-esc="true"
                 :no-close-on-backdrop="true"
                 :hide-header-close="true"
                 ref="superomaticModal"
                 @cancel="goHome"
                 @esc="goHome"
                 @backdrop="goHome"
                 @headerclose="goHome"

        >
            <b-card no-body class="col-12 border-0 text-white bg-transparent">
                <div class="form-group">
                    <div class="col-md-12 mx-auto my-3 text-center px-0">
                        <div class="radio col-4 mx-auto text-left">
                            <label>
                                <input v-model="denomination" type="radio" value="0.1">
                                0.1
                            </label>
                        </div>
                        <div class="radio col-4 mx-auto text-left">
                            <label>
                                <input v-model="denomination" type="radio" value="0.25">
                                0.25
                            </label>
                        </div>
                        <div class="radio col-4 mx-auto text-left">
                            <label>
                                <input v-model="denomination" type="radio" value="0.5">
                                0.5
                            </label>
                        </div>
                        <div class="radio col-4 mx-auto text-left">
                            <label>
                                <input v-model="denomination" type="radio" value="1">
                                1
                            </label>
                        </div>

                        <div class="row w-100 mx-0 mt-2 justify-content-center">
                            <div class="col-11 col-lg-6 px-0 mx-0 mx-lg-2 mx-auto">
                                <button name="submit" type="submit" class="btn btn-orange w-100"
                                        @click.prevent="gotoSuperoView()">
                                    Играть
                                </button>
                            </div>

                            <div class="col-11 col-lg-6 px-0 mx-2 mt-3 text-right mx-auto text-center">
                                <a class="btn btn-gray white-color w-50" @click="goHome()">Отмена</a>
                            </div>
                        </div>

                    </div>
                </div>
            </b-card>

        </b-modal>

    </div>
</template>
<script>
    export default {
        name: 'SuperomaticSelecter',
        props: ['game'],
        data() {
            return {
                denomination: 1
            }
        },
        computed: {
            isMobile() {
                return this.$_config.environment.mobile
            }
        },
        methods: {
            gotoSuperoView() {
                this.$emit('superoNominationSelected', this.denomination)
                this.$root.$emit('bv::hide::modal', 'superomaticModal')
                return false
            },
            goHome() {
                this.$router.push({path: '/'})
            }
        }
    }
</script>

<style scoped>
    .bg-transparent {
        background-color: transparent;
    }
</style>
