<template>
    <b-modal id="changePasswordModal" title="Изменение пароля" size="lg" :hide-footer="true">
        <b-card no-body class="col-12 border-0 px-0" v-if="userData">

            <b-tabs card class="change-pass-tab px-0">

                <b-tab title="Почта" :title-item-class="'changePasswordTabs mt-2 mt-lg-0'">
                    <form @submit.prevent="changePasswordWithEmail" class="mt-3">
                        <div class="form-group row mx-0">
                            <div class="col-3 text-right">
                                <label class="col-form-label white-color">Ваша Почта</label>
                            </div>
                            <div class="col-9">
                                <input type="email" class="form-control here" v-model="restoreEmailPayload.email">
                            </div>
                        </div>
                        <div class="form-group row mx-0">
                            <div class="col-3">
                                <Captcha ref="pass_change_email_captcha" :captcha_key="'pass_change_captcha'"></Captcha>
                            </div>
                            <div class="col-9">
                                <input placeholder="Введите символы с картинки" type="text" class="form-control here"
                                       v-model="restoreEmailPayload.captcha">
                            </div>
                        </div>
                        <Alert :message="emailSentResponse"></Alert>

                        <div class="form-group row mx-0" v-if="!isEmailCodeSent">

                            <div class="mx-auto col-10 col-md-6">
                                <button class="btn w-100 btn-orange" name="submit" type="submit" @click.prevent="sendEmailCode">
                                    Отправить письмо с кодом
                                </button>
                            </div>

                        </div>
                        <div class="col-12 px-0" v-if="isEmailCodeSent">
                            <div class="form-group row mx-0">
                                <div class="col-3">
                                    <label class="col-form-label text-white">Код из письма</label>
                                </div>
                                <div class="col-9">
                                    <input class="form-control here" v-model="restoreEmailPayload.email_sent_code">
                                </div>
                            </div>
                            <div class="form-group row mx-0">
                                <div class="col-3">
                                    <label class="col-form-label text-white">Новый пароль</label>
                                </div>
                                <div class="col-9">
                                    <input type="password" class="form-control here"
                                           v-model="restoreEmailPayload.new_password">
                                </div>
                            </div>
                            <div class="form-group row mx-0">
                                <div class="col-3 ">
                                    <label class="col-form-label text-white">Повторите новый пароль</label>
                                </div>
                                <div class="col-9">
                                    <input type="password" class="form-control here"
                                           v-model="restoreEmailPayload.new_password_confirm">
                                </div>
                            </div>

                            <Alert :message="passChangeEmailResponse"></Alert>

                            <div class="form-group row mx-0">
                                <div class="col-10 col-md-6 mx-auto">
                                    <button class="btn w-100 btn-orange" name="submit" type="submit"
                                            :disabled="passChangeEmailSubmitting">
                                        Изменить пароль
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </b-tab>

                <b-tab title="Телефон" :title-item-class="'changePasswordTabs mt-2 mt-lg-0'"
                       v-if="mainSettings && mainSettings.phoneActionsMethod==='call'">
                    <form @submit.prevent="changePasswordWithCall" class="mt-3">
                        <div class="form-group row mx-0 mx-0">

                            <div class="col-3 text-right">
                                <label class="col-form-label white-color">Тел. номер</label>
                            </div>
                            <div class="col-9">
                                <input type="text" class="form-control here"
                                       v-model="restoreCallPayload.phone"
                                       :disabled="userData.phone_confirmed===1? 'disabled': false"
                                       v-mask="'+#(###)-###-##-##'">
                            </div>
                        </div>
                        <div class="form-group row mx-0 mx-0">
                            <div class="col-3">
                                <Captcha ref="password_change_sms" :captcha_key="'password_change_sms'"></Captcha>
                            </div>
                            <div class="col-9">
                                <input placeholder="Введите символы с картинки"
                                       type="text" class="form-control here"
                                       v-model="restoreCallPayload.captcha">
                            </div>
                        </div>

                        <Alert :message="callSentResponse"></Alert>

                        <div class="form-group row mx-0 mx-0" v-if="!isCallCodeSent">
                            <div class="col-10 col-md-6 mx-auto">
                                <button class="btn w-100 btn-orange" name="submit"
                                        type="submit" @click.prevent="sendCallCode">
                                    Звонить на номер
                                </button>
                            </div>
                        </div>
                        <div class="col-12 px-0" v-if="isCallCodeSent">
                            <div class="form-group row mx-0 mx-0">
                                <label class="col-12 col-form-label text-white">Последние 4 цифры номера  с которого поступил звонок</label>
                                <div class="col-12">
                                    <input type="text" class="form-control here"
                                           v-model="restoreCallPayload.phone_sent_code">
                                </div>
                            </div>
                            <div class="form-group row mx-0 mx-0">
                                <label class="col-3 col-form-label  text-white">Новый пароль</label>
                                <div class="col-9">
                                    <input type="password" class="form-control here"
                                           v-model="restoreCallPayload.new_password">
                                </div>
                            </div>
                            <div class="form-group row mx-0 mx-0">
                                <label class="col-3 col-form-label  text-white">Повторите новый пароль</label>
                                <div class="col-9">
                                    <input type="password" class="form-control here"
                                           v-model="restoreCallPayload.new_password_confirm">
                                </div>
                            </div>
                            <Alert :message="passChangeCallResponse"></Alert>
                            <div class="form-group row mx-0 mx-0">

                                <div class="col-10 col-md-6 mx-auto">
                                    <button class="btn w-100 btn-orange" name="submit" type="submit"
                                            :disabled="passChangeCallResponse">
                                        Изменить пароль
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>

                </b-tab>

                <b-tab title="Через СМС" :title-item-class="'changePasswordTabs mt-2 mt-lg-0'" v-else>
                <form @submit.prevent="changePasswordWithSms" class="mt-3">
                  <div class="form-group row mx-0 mx-0">

                    <div class="col-3 text-right">
                      <label class="col-form-label white-color">Тел. номер</label>
                    </div>
                    <div class="col-9">
                      <input type="text" class="form-control here" v-model="restoreSmsPayload.phone"
                             v-mask="'+#(###)-###-##-##'">
                    </div>
                  </div>
                  <div class="form-group row mx-0 mx-0">
                    <div class="col-3">
                      <Captcha ref="password_change_sms" :captcha_key="'password_change_sms'"></Captcha>
                    </div>
                    <div class="col-9">
                      <input placeholder="Введите символы с картинки" type="text" class="form-control here"
                             v-model="restoreSmsPayload.captcha">
                    </div>
                  </div>

                  <Alert :message="smsSentResponse"></Alert>

                  <div class="form-group row mx-0 mx-0" v-if="!isPhoneCodeSent">
                    <div class="col-10 col-md-6 mx-auto">
                      <button class="btn w-100 btn-orange" name="submit"
                              type="submit" @click.prevent="sendSmsCode">
                        Отправить смс с кодом
                      </button>
                    </div>
                  </div>
                  <div class="col-12 px-0" v-if="isPhoneCodeSent">
                    <div class="form-group row mx-0 mx-0">
                      <label class="col-3 col-form-label">Код из смс</label>
                      <div class="col-9">
                        <input type="text" class="form-control here"
                               v-model="restoreSmsPayload.phone_sent_code">
                      </div>
                    </div>
                    <div class="form-group row mx-0 mx-0">
                      <label class="col-3 col-form-label">Новый пароль</label>
                      <div class="col-9">
                        <input type="password" class="form-control here"
                               v-model="restoreSmsPayload.new_password">
                      </div>
                    </div>
                    <div class="form-group row mx-0 mx-0">
                      <label class="col-3 col-form-label">Повторите новый пароль</label>
                      <div class="col-9">
                        <input type="password" class="form-control here"
                               v-model="restoreSmsPayload.new_password_confirm">
                      </div>
                    </div>
                    <Alert :message="passChangePhoneResponse"></Alert>
                    <div class="form-group row mx-0 mx-0">

                      <div class="col-10 col-md-6 mx-auto">
                        <button class="btn w-100 btn-orange" name="submit" type="submit"
                                :disabled="passChangePhoneSubmitting">
                          Изменить пароль
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

              </b-tab>

            </b-tabs>
        </b-card>

    </b-modal>
</template>

<script>
    import Alert from '@/components/Alert'
    import Captcha from '@/components/Captcha'

    export default {
        props: ['userData'],
        name: 'ChangePassword',
        computed: {
          mainSettings () {
            return this.$store.getters.getMainSettings
          }
        },
        data() {
            return {
                isSubmitting: false,
                isEmailCodeSent: false,
                isPhoneCodeSent: false,
                isCallCodeSent: false,
                emailSentResponse: null,
                smsSentResponse: null,
                callSentResponse: null,
                passChangeEmailResponse: null,
                passChangePhoneResponse: null,
                passChangeCallResponse: null,
                passChangeEmailSubmitting: false,
                passChangePhoneSubmitting: false,
                passChangeCallSubmitting: false,
                restoreEmailPayload: {
                    email: '',
                    captcha: '',
                    email_sent_code: '',
                    new_password: '',
                    new_password_confirm: ''
                },
                restoreSmsPayload: {
                    phone: '',
                    captcha: '',
                    phone_sent_code: '',
                    new_password: '',
                    new_password_confirm: ''
                },
                restoreCallPayload: {
                    phone: '',
                    captcha: '',
                    phone_sent_code: '',
                    new_password: '',
                    new_password_confirm: ''
                }
            }
        },
        components: {
            Captcha,
            Alert
        },
        methods: {
            sendEmailCode() {
                if (!this.userData.email.length || !this.restoreEmailPayload.captcha.length) {
                    this.emailSentResponse = {status: 'error', msg: 'Заполните поля почты и капчи'}
                    return 0
                }
                this.emailSentResponse = {status: 'loading'}
                this.axios.post(
                    this.$_config.passwordChangeEmailPath,
                    `email=${this.restoreEmailPayload.email}&captcha=${this.restoreEmailPayload.captcha}`,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.emailSentResponse = data
                    if (data.status === 'ok') {
                        this.isEmailCodeSent = true
                    }
                    this.restoreEmailPayload.captcha = ''
                })
                this.$refs.pass_change_email_captcha.updateCaptcha()
            },
            sendSmsCode() {
                if (!this.restoreSmsPayload.phone.length || !this.restoreSmsPayload.captcha.length) {
                    this.smsSentResponse = {status: 'error', msg: 'Заполните все поля'}
                    return 0
                }
                this.smsSentResponse = {status: 'loading'}
                this.axios.post(
                    this.$_config.passwordChangeSmsPath,
                    `phone=${this.restoreSmsPayload.phone}&captcha=${this.restoreSmsPayload.captcha}`,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    if (data.status === 'ok') {
                        this.isPhoneCodeSent = true
                    }
                    this.smsSentResponse = data
                    this.restoreSmsPayload.captcha = ''
                }).catch(err => {
                    console.log(err)
                })

                setTimeout(() => {
                    this.$refs.password_change_sms.updateCaptcha()
                }, 1000)
            },
            sendCallCode() {
                if (!this.restoreCallPayload.phone.length || !this.restoreCallPayload.captcha.length) {
                    this.callSentResponse = {status: 'error', msg: 'Заполните все поля'}
                    return 0
                }
                this.callSentResponse = {status: 'loading'}
                this.axios.post(
                    this.$_config.passwordChangeCallPath,
                    `phone=${this.restoreCallPayload.phone}&captcha=${this.restoreCallPayload.captcha}`,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    if (data.status === 'ok') {
                        this.isCallCodeSent = true
                    }
                    this.callSentResponse = data
                    this.restoreCallPayload.captcha = ''
                }).catch(err => {
                    console.log(err)
                })

                setTimeout(() => {
                    this.$refs.password_change_sms.updateCaptcha()
                }, 1000)
            },
            changePasswordWithEmail() {
                this.passChangeEmailResponse = {status: 'loading'}
                this.passChangeEmailSubmitting = true
                let sendPayload = {
                    new_password: this.restoreEmailPayload.new_password,
                    new_password_confirm: this.restoreEmailPayload.new_password_confirm,
                    restore_code: this.restoreEmailPayload.email_sent_code,
                    password_change_captcha: this.restoreEmailPayload.captcha
                }
                let queryString = this.$_config.makeQueryStringFromObject(sendPayload)
                this.axios.post(
                    `${this.$_config.passwordChangeRequests.mail}`,
                    queryString,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.passChangeEmailResponse = data
                    this.passChangeEmailSubmitting = false
                    this.restoreEmailPayload.captcha = ''
                    if (data.status === 'ok') {
                        setTimeout(() => {
                            this.$root.$emit('bv::hide::modal', 'changePasswordModal')
                        }, 3000)
                    }
                }).catch(err => {
                    if (err) {
                        this.passChangeEmailResponse = {
                            status: 'error',
                            msg: 'Ошибка при отправке запроса, попробуйте снова.'
                        }
                    }
                    this.passChangeEmailSubmitting = false
                    this.restoreEmailPayload.captcha = ''
                })
                this.$refs.pass_change_email_captcha.updateCaptcha()
            },
            changePasswordWithSms() {
                this.passChangePhoneResponse = {status: 'loading'}
                this.passChangePhoneSubmitting = true
                let sendPayload = {
                    new_password: this.restoreSmsPayload.new_password,
                    new_password_confirm: this.restoreSmsPayload.new_password_confirm,
                    restore_code: this.restoreSmsPayload.phone_sent_code,
                    password_change_captcha: this.restoreSmsPayload.captcha
                }
                let queryString = this.$_config.makeQueryStringFromObject(sendPayload)
                this.axios.post(
                    `${this.$_config.passwordChangeRequests.sms}`,
                    queryString,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.passChangePhoneResponse = data
                    this.passChangePhoneSubmitting = true
                    if (data.status === 'ok') {
                        setTimeout(() => {
                            this.$root.$emit('bv::hide::modal', 'changePasswordModal')
                        }, 3000)
                    }
                }).catch(err => {
                    if (err) {
                        this.passChangePhoneResponse = {
                            status: 'error',
                            msg: 'Ошибка при отправке запроса, попробуйте снова.'
                        }
                    }
                    this.passChangePhoneSubmitting = true
                })
                this.$refs.password_change_sms.updateCaptcha()
            },
            changePasswordWithCall() {
                this.passChangeCallResponse = {status: 'loading'}
                this.passChangeCallSubmitting = true
                let sendPayload = {
                    new_password: this.restoreCallPayload.new_password,
                    new_password_confirm: this.restoreCallPayload.new_password_confirm,
                    restore_code: this.restoreCallPayload.phone_sent_code,
                    password_change_captcha: this.restoreCallPayload.captcha
                }
                let queryString = this.$_config.makeQueryStringFromObject(sendPayload)
                this.axios.post(
                    `${this.$_config.passwordChangeRequests.call}`,
                    queryString,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.passChangeCallResponse = data
                    this.passChangeCallSubmitting = true
                    if (data.status === 'ok') {
                        setTimeout(() => {
                            this.$root.$emit('bv::hide::modal', 'changePasswordModal')
                        }, 3000)
                    }
                }).catch(err => {
                    if (err) {
                        this.passChangeCallResponse = {
                            status: 'error',
                            msg: 'Ошибка при отправке запроса, попробуйте снова.'
                        }
                    }
                    this.passChangeCallSubmitting = true
                })
                this.$refs.password_change_sms.updateCaptcha()
            },
        },
        mounted() {
            if (this.userData) {
                this.restoreEmailPayload.email = this.userData.email
                this.restoreSmsPayload.phone = this.userData.phone
                this.restoreCallPayload.phone = this.userData.phone
            }
        }
    }
</script>

<style>
    .change-pass-tab .card-header {
        background-color: transparent;
        border: none;
        padding-left: 0;
        padding-right: 0;
    }

    .change-pass-tab .nav-tabs {
        border: none !important;
        font-size: 20px;
        justify-content: center;
    }

    .change-pass-tab .nav-tabs .nav-item {
        width: 40% !important;
        border: none !important;
        margin: 0;
    }

    .change-pass-tab .nav-tabs .nav-item .nav-link {
        color: white !important;
        background-color: rgba(124, 128, 136, 0.3);
        border: none;
    }

    .change-pass-tab .nav-tabs .nav-item .nav-link.active {
        box-shadow: 0 0 10px 2px #ffe0036e !important;
    }

    .change-pass-tab .nav-tabs .nav-item .nav-link {
        border-radius: 30px;
        margin: 0 15px;
        text-align: center;
    }

    .change-pass-tab .nav-tabs .nav-item:first-child .nav-link::before,
    .change-pass-tab .nav-tabs .nav-item:last-child .nav-link::before {
        margin-right: 10px;
    }

    .change-pass-tab .card-body,
    #changePasswordModal .modal-body {
        padding-left: 0;
        padding-right: 0;
    }

    @media (min-width: 320px) and (max-width: 1366px) {
        .change-pass-tab .nav-tabs .nav-item {
            width: 100% !important;
            border: none !important;
            margin: 0;
        }

        .change-pass-tab .nav-tabs .nav-item .nav-link {
            padding-left: 0;
            padding-right: 0;
            font-size: 16px;
        }
    }
</style>

<style scoped>
    form input {
        border-radius: 20px !important;
        border-color: #b9c0c5 !important;
    }
</style>
