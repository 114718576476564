<template>
    <div class="terms-container pt-5 col-12 col-lg-9 mx-auto">
        <div class="row mx-0 w-100" v-html="terms" v-if="terms"></div>
        <div class="row mx-0 justify-content-center w-100" v-else>
            <i class="fa fa-spinner fa-spin fa-fw my-5 orange-color"></i>
        </div>
    </div>
</template>

<script>
  export default {
    computed: {
      terms() {
        return this.$store.getters['getTerms']
      }
    }
  }
</script>

