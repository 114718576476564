<template>
    <b-modal id="flashMessagesModal" v-model="canShowMessages" size="lg" title="Системное сообщение" :hide-footer="true">
        <Alert v-for="(msg, idx) in flashMessages" :key="idx" :message="{status:'ok', msg:msg}"></Alert>
        <div class="col-12 px-0 text-center">
            <div class="d-inline-block my-2">
                <button name="submit" type="submit" class="btn btn-orange white-color w-100 px-3" @click.prevent="unsetFlashMessages">
                    Отметить как прочитанное
                </button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import Alert from './Alert'
export default {
    name: 'FlashMessages',
    computed: {
        flashMessages () {
            return this.$store.getters['getFlashMessages']
        },
        canShowMessages () {
          return !!this.flashMessages
        }
    },
    methods: {
        unsetFlashMessages () {
            this.$store.dispatch('unsetFlashMessages')
        }
    },
    components: {
        Alert
    }
}
</script>
