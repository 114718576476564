<template>
    <div class="container-fluid winner-list px-0">
        <h4 class="text-center">Победители:</h4>

        <b-carousel :interval="3000" controls style="width: 100%" class="winners-carousel">

            <b-carousel-slide>
                <div class="mr-3 d-inline-block carousel-winner-itm" v-for="(winner, index) in winners" :key="index" v-if="index < 11">
                    <span>{{winner.user}}</span>
                    <span class="win-amount"> {{winner.amount}}</span>
                </div>
            </b-carousel-slide>

            <b-carousel-slide>
                <div class="mr-3 d-inline-block carousel-winner-itm" v-for="(winner, index) in winners" :key="index"
                     v-if="index > 10 && index < 21">
                    <span>{{winner.user}}</span>
                    <span class="win-amount"> {{winner.amount}}</span>
                </div>
            </b-carousel-slide>

            <b-carousel-slide>
                <div class="mr-3 d-inline-block carousel-winner-itm" v-for="(winner, index) in winners" :key="index"
                     v-if="index > 20 && index < 31">
                    <span>{{winner.user}}</span>
                    <span class="win-amount"> {{winner.amount}}</span>
                </div>
            </b-carousel-slide>

        </b-carousel>
    </div>
</template>


<script>
    export default {
        name: 'Home',
        computed: {
            winners() {
                return this.$store.getters['getWinnersList']
            }
        }
    }
</script>

<style>
    .winners-carousel .carousel-winner-itm:last-child,
    .winners-carousel .carousel-winner-itm:first-child{
        opacity: .2;
    }

    .winners-carousel .carousel-control-next,
    .winners-carousel .carousel-control-prev{
        opacity: 1 !important;
        z-index: 15;
    }

    .winner-list .carousel-caption {
        width: 82%;
        left: 9%;
        overflow-x: hidden;
        white-space: nowrap;
    }

    .winner-list .carousel-item {
        height: 100px;
    }

    .winner-list .carousel-caption span{
        font-size: 15px;
    }

    @media (min-width: 320px) and (max-width: 1366px) {
        .winner-list .carousel-caption {
            white-space: normal;
        }

        .winner-list .carousel-caption span{
            font-size: 12px;
        }
    }
</style>
<style scoped>
    h4 {
        color: white;
    }

    .win-amount {
        color: #f6d307d9;
    }

    @media (min-width: 320px) and (max-width: 600px) {
        .winner-list .carousel-item {
            height: 160px;
        }
    }
    @media (min-width: 600px) and (max-width: 1366px) {
        .winner-list .carousel-item {
            height: 120px;
        }
    }

</style>
