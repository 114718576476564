<template>
    <div class="home-container pt-2 pt-lg-0">
        <slider></slider>
        <img :src="vawe" class="w-100 my-2 my-lg-4" alt="LentaSlot - Online Slots">
        <div class="main-content col-12 col-lg-12 col-xl-10 px-0 mx-auto">
            <winner-list></winner-list>
        </div>
        <img :src="vawe" class="w-100 mb-0 mb-lg-5" alt="LentaSlot - Online Slots">
        <div class="main-content col-12 col-lg-12 col-xl-10 px-0 mx-auto">
            <game-list></game-list>
        </div>
        <img :src="vawe_double" class="double-vawe w-100" alt="LentaSlot - Online Slots">
    </div>
</template>

<script>
    import Slider from '@/components/home/Slider'
    import WinnerList from '@/components/home/WinnerList'
    import GameList from '@/components/games/GameList'

    export default {
        name: 'Home',
        components: {
            Slider,
            WinnerList,
            GameList
        },
        data() {
            return {
                vawe: require('@/../public/images/vawe.png'),
                vawe_double: require('@/../public/images/double_vawe.png')
            }
        }
    }
</script>

<style>
    .double-vawe {
        height: 23px;
    }
</style>
