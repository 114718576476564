<template>
    <div class="forgot-pass-container col-12 px-0 py-2">

        <div class="page-title white-color text-center pt-5">
            <h1 class="mt-3 mt-lg-5">Восстановление пароля</h1>
        </div>

        <div class="col-12 col-lg-8 col-xl-6 mt-5 px-0 mx-auto">
            <b-tabs content-class="mt-3" class="tab pass-recover-tab px-0">
                <b-tab class="tab-item" title="Email" active>
                    <recover-by-email></recover-by-email>
                </b-tab>
                <b-tab class="tab-item" title="Телефон" v-if="mainSettings && mainSettings.phoneActionsMethod ==='call'">
                    <recover-by-call></recover-by-call>
                </b-tab>
                <b-tab class="tab-item" title="SMS" v-else>
                    <recover-by-sms></recover-by-sms>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
    import RecoverByEmail from '@/components/user/passRecover/RecoverViaEmail'
    import RecoverBySms from '@/components/user/passRecover/RecoverViaSms'
    import RecoverByCall from '@/components/user/passRecover/RecoverViaCall'

    export default {
        components: {
            RecoverByEmail,
            RecoverBySms,
            RecoverByCall
        },
        computed: {
            userData() {
                return this.$store.getters['userData']
            },
            mainSettings () {
                return this.$store.getters.getMainSettings
            }
        },
        watch: {
            userData(newValue) {
                if (newValue) {
                    this.$router.push({name: 'home'})
                }
            }
        },
        mounted() {
            if (this.userData) {
                this.$router.push({name: 'home'})
            }
        }
    }
</script>

<style>
    .pass-recover-tab .nav-tabs {
        border: none !important;
        margin-bottom: 30px;
        font-size: 20px;
        justify-content: center;
    }

    .pass-recover-tab .nav-tabs .nav-item {
        width: 40% !important;
        border: none !important;
    }

    .pass-recover-tab .nav-tabs .nav-item .nav-link {
        color: white !important;
        background-color: rgba(124, 128, 136, 0.3);;
        border: none;
    }

    .pass-recover-tab .nav-tabs .nav-item .nav-link.active {
        box-shadow: 0 0 10px 2px #ffe0036e  !important;
    }

    .pass-recover-tab .nav-tabs .nav-item .nav-link {
        color: white !important;
        background-color: rgba(124, 128, 136, 0.3);
        box-shadow: 0 0 10px 2px rgba(124, 128, 136, 0.3)  !important;
        border: none;
        border-radius: 30px;
        text-align: center;
    }

    .pass-recover-tab .nav-tabs .nav-item:first-child .nav-link {
        margin-right: 10px;
    }

    .pass-recover-tab .nav-tabs .nav-item:last-child .nav-link {
        margin-left: 10px;
    }

    .pass-recover-tab .nav-tabs .nav-item:first-child .nav-link::before {
        content: url("../../../public/images/icons/recover/email.png");
        margin-right: 10px;
    }

    .pass-recover-tab .nav-tabs .nav-item:last-child .nav-link::before {
        content: url("../../../public/images/icons/recover/sms.png");
        margin-right: 10px;
    }

    .recover-form input {
        border-radius: 20px !important;
        border: none;
    }

    @media (min-width: 320px) and (max-width: 1366px) {
        .forgot-pass-container h1 {
            font-size: 24px;
        }

        .pass-recover-tab .nav-tabs .nav-item {
            width: 100% !important;
            border: none !important;
            margin: 0;
            margin-bottom: 8px;
        }

        .pass-recover-tab .nav-tabs .nav-item .nav-link {
            padding-left: 0;
            padding-right: 0;
            font-size: 16px;
        }
    }
</style>

