import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import Socket from '@/socket'
import BootstrapVue from 'bootstrap-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Config from '@/config'
import VueTheMask from 'vue-the-mask'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/../public/styles/fontawesome.css'
import '@/../public/styles/main.css'
import VueLazyload from 'vue-lazyload'

const Chat = require('@/common/chat.js');
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(Config);
Vue.use(VueTheMask);
Vue.use(VueLazyload);

axios.defaults.withCredentials = true;
Vue.config.productionTip = false;

store.dispatch('fetchUserData').then(async resp => {
  Vue.use(Socket, store);
  if (resp.login) {
    Chat({
      login: resp.login + ', ' + resp.id + ', ' + 'LentaSlot',
      email: resp.email
    })
  }else {
    Chat()
  }

  new Vue({
    el: '#app',
    router,
    store,
    template: '<App/>',
    components: {App},
    render: h => h(App),
    async mounted() {
      await this.$store.dispatch('fetchApiData')
    }
  })
})
