<template>
    <div class="row mx-0 navbar-container">
        <div class="col-12 col-xl-12 mx-auto px-0">
            <b-navbar toggleable="lg" type="dark" variant="custom" class="main-menu pt-0 pt-xl-1 px-0">

                <b-navbar-toggle target="nav-collapse">
                    <i class="fa fa-close text-white" v-if="collapsed"></i>
                </b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav class="pt-0 row mx-0 justify-content-between">
                    <div class="col-3 col-lg-2 d-none d-lg-block">
                        <b-navbar-brand class="mx-0 px-0">
                            <router-link :to="{ name: 'home' }" class="d-inline-block middle-logo-container ">
                                <img class="header-logo  px-3" :src="logo" alt="LentaSlot">
                            </router-link>
                        </b-navbar-brand>
                    </div>
                    <!--menu list-->
                    <div class="col-10 col-lg-6 px-4 px-lg-0 menu-list">
                        <b-navbar-nav class="justify-content-between pt-3 pt-lg-2">
                            <b-nav-item class="mx-0 mx-lg-1 d-block d-lg-none">
                                <router-link :to="{ name: 'home' }" exact class="px-0 d-block text-center pb-2">
                                    <img :src="logo" alt="LentaSlot">
                                </router-link>
                            </b-nav-item>

                            <b-nav-item class="mx-0 mx-lg-1">
                                <router-link :to="{ name: 'home' }" active-class="active-page" exact
                                             class="px-0 d-block pb-2 pb-lg-0 pb-xl-1 pb-lg-0 pb-xl-1">
                                    <img class="icon mr-2" :src="icons.home" alt="Казино">
                                    <span class="menu-item">Казино</span>
                                </router-link>
                            </b-nav-item>

                            <b-nav-item class="mx-0 mx-lg-1" v-if="siteSettings && siteSettings.live">
                                <router-link :to="{ name: 'LiveGames' }" active-class="active-page" exact
                                             class="px-0 d-block pb-2 pb-lg-0 pb-xl-1">
                                    <img class="icon mr-2" :src="icons.live" alt="Live Casino">
                                    <span class="menu-item">Live <span class="casino-in-live">Casino</span> </span>
                                </router-link>
                            </b-nav-item>

                            <b-nav-item class="mx-0 mx-lg-1">
                                <router-link
                                        :to="{ name: 'TheGame', params: {gameName:'KronosWheel', gameId:1, provider: 'WheelOfFortune'}}"
                                        active-class="active-page" exact
                                        class="px-0 d-block pb-2 pb-lg-0 pb-xl-1">
                                    <img class="icon mr-2" :src="icons.wheel" alt="Колесо">
                                    <span class="menu-item">Колесо</span>
                                </router-link>
                            </b-nav-item>
                            <b-nav-item class="mx-0 mx-lg-1">
                                <router-link :to="{ name: 'deposit' }" active-class="active-page" exact
                                             class="px-0 d-block pb-2 pb-lg-0 pb-xl-1">
                                    <img class="icon mr-2" :src="icons.deposit" alt="Депозит">
                                    <span class="menu-item">Депозит</span>
                                </router-link>
                            </b-nav-item>
                            <b-nav-item class="mx-0 mx-lg-1">
                                <router-link :to="{ name: 'withdraw' }" active-class="active-page" exact
                                             class="px-0 d-block pb-2 pb-lg-0 pb-xl-1">
                                    <img class="icon mr-2" :src="icons.withdraw" alt="Выплаты">
                                    <span class="menu-item">Выплаты</span>
                                </router-link>
                            </b-nav-item>
                            <b-nav-item class="mx-0 mx-lg-1">
                                <router-link :to="{ name: 'bonuses' }" active-class="active-page" exact
                                             class="px-0 d-block pb-2 pb-lg-0 pb-xl-1">
                                    <img class="icon mr-2" :src="icons.bonus" alt="Бонусы">
                                    <span class="menu-item">Бонусы</span>
                                </router-link>
                            </b-nav-item>
                            <b-nav-item class="mx-0 mx-lg-1 d-block">
                                <router-link :to="{ name: 'terms' }" active-class="active-page" exact
                                             class="px-0 d-block pb-2 pb-lg-0 pb-xl-1">
                                    <img class="icon mr-2" :src="icons.terms" alt="Условия">
                                    <span class="menu-item">Условия</span>
                                </router-link>
                            </b-nav-item>
                            <b-nav-item class="mx-0 mx-lg-1 d-block">
                                <router-link :to="{ name: 'UserLevels' }" active-class="active-page" exact
                                             class="px-0 d-block pb-2 pb-lg-0 pb-xl-1">
                                    <img class="icon mr-2" :src="icons.levels" alt="Уровень">
                                    <span class="menu-item">Уровень</span>
                                </router-link>
                            </b-nav-item>
                        </b-navbar-nav>
                    </div>

                    <!--auth buttons area-->
                    <div class="col-12 col-lg-3 d-none d-lg-block">
                        <b-navbar-nav v-if="!userData">
                            <li class="w-100">
                                <div class="auth-btns row mx-0 w-100 justify-content-lg-end">
                                    <div class="col-lg-5 text-right">
                                        <router-link class="btn login-btn white-color px-2 px-xl-3" :to="{ name: 'login' }">
                                            Авторизация
                                        </router-link>
                                    </div>
                                    <div class="col-lg-6 col-xl-7 pl-3 pr-0 pulse mx-auto">
                                        <router-link class="btn btn-orange d-block px-1 px-xl-3" :to="{ path: '/registration' }">
                                            Регистрация
                                        </router-link>
                                    </div>
                                </div>
                            </li>
                        </b-navbar-nav>
                        <b-navbar-nav v-else>
                            <li class="w-100">
                                <div class="col-10 mb-2 text-center float-left ">
                                    <router-link :to="{ name: 'account', params : {placeName: 'profile'} }" class="account-info">
                                        <span class="white-color mr-1">{{userData.login}}</span><br>
                                    </router-link>
                                  <span class="green-color font-weight-bold">
                                    Баланс: {{!balance?userData.balance : balance}} {{userData.currency_name}}
                                  </span>
                                </div>
                                <div class="col-4 col-md-2 mt-3 px-0 text-center float-left">
                                    <a class="logout-btn white-color p-2" @click.prevent="logout">Выйти</a>
                                </div>
                            </li>
                        </b-navbar-nav>
                    </div>

                </b-collapse>

                <div class="row w-100 d-lg-none mx-0 mobile-header-menu">
                    <!--mobile header logo. when menu closed-->
                    <div class="col-2 col-md-3 px-0"></div>
                    <div class="col-8 col-md-6 px-0 mt-2 text-center">
                        <b-navbar-brand class="mx-0 px-0">
                            <router-link :to="{ name: 'home' }" class="mr-2 ">
                                <img class="px-3 header-logo" :src="logo" alt="LentaSlot">
                            </router-link>
                        </b-navbar-brand>
                    </div>

                    <div class="col-2 col-md-3 px-0 accout-mobile text-right">
                        <div v-if="!userData" class="text-right px-2 px-sm-3 px-md-3">
                            <router-link :to="{ name: 'login' }" exact>
                                <img :src="userIconMobile" class="login-icon" alt="LentaSlot Account">
                            </router-link>
                        </div>
                        <div v-else>
                            <b-dropdown class="account-icon mt-2">
                                <template v-slot:button-content>
                                    <img :src="userIcon" class="mr-0 mr-sm-2 mt-2" alt="LentaSlot Account">
                                </template>
                                <b-dropdown-item v-if="siteSettings && siteSettings.balanceTransferEnabled">
                                  <router-link :to="{ name: 'account',params: {placeName: 'balances'} }">
                                    <span class="green-color font-weight-bold">Баланс: {{!balance?userData.balance : balance}} {{userData.currency_name}}</span>
                                    <br>
                                    <span class="green-color font-weight-bold" >
                                      Кошелек: {{userData.main_balance}} {{userData.currency_name}}
                                    </span>
                                  </router-link>
                                </b-dropdown-item>
                              <b-dropdown-item v-else>
                                <span class="green-color font-weight-bold">Баланс: {{!balance?userData.balance : balance}} {{userData.currency_name}}</span>
                              </b-dropdown-item>
                                <b-dropdown-item>
                                    <router-link :to="{ name: 'account',params: {placeName: 'profile'} }"> Профиль</router-link>
                                </b-dropdown-item>
                                <b-dropdown-item>
                                    <a href="#" @click.prevent="logout"> Выйти</a>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>

                </div>

            </b-navbar>

        </div>
    </div>

</template>

<script>
    export default {
        name: 'RwvHeader',
        data() {
            return {
                gamesSearchText: '',
                headerLogo: true,
                collapsed: true,
                logo: require('@/../public/images/logos/logo.png'),
                userIcon: require('@/../public/images/icons/user-icon.png'),
                userIconMobile: require('@/../public/images/icons/header-user-icon.png'),
                icons: {
                    live: require('@/../public/images/icons/menu-icons/live.svg'),
                    wheel: require('@/../public/images/icons/menu-icons/wheel.png'),
                    bonus: require('@/../public/images/icons/menu-icons/bonus.png'),
                    deposit: require('@/../public/images/icons/menu-icons/deposit.png'),
                    withdraw: require('@/../public/images/icons/menu-icons/withdraw.png'),
                    terms: require('@/../public/images/icons/menu-icons/terms.png'),
                    download: require('@/../public/images/icons/menu-icons/download.png'),
                    levels: require('@/../public/images/icons/menu-icons/live.svg'),
                    home: require('@/../public/images/icons/menu-icons/home.png'),
                },
            }
        },
        computed: {
            userData() {
                return this.$store.getters['userData']
            },
            balance() {
                return this.$store.getters['getBalance']
            },
            siteSettings () {
                return this.$store.getters.getMainSettings
            }
        },
        methods: {
            logout() {
                this.axios.get(
                    this.$_config.baseUrl + '/Api/logout'
                ).then(() => {
                    this.$store.dispatch('removeUserData')
                    if (this.$route.name !== 'home') {
                        return this.$router.push({name: 'home'})
                    } else {
                        window.location.reload(true)
                    }
                }).catch(err => {
                    console.log(err)
                })
            },
            gotoGameView() {
                this.$router.push({
                    name: 'TheGame',
                    params: {
                        provider: 'wheeloffortune',
                        gameId: 1
                    }
                })
            }
        },
        mounted() {
            this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
                if (collapseId === 'nav-collapse') {
                    isJustShown ? this.collapsed = true : this.collapsed = false
                }
            })
        },
    }
</script>

<style>
    .header-logo{
        max-width: 100%;
        object-fit: contain;
    }
    .dropdown-item a {
        color: #0b0b18;
        text-decoration: none;
    }
    .dropdown-menu{
      min-width: 12rem !important;
    }
    .dropdown-item:hover {
        background-color: #0b0b18 !important;
    }

    .dropdown-item:hover a {
        color: white !important;
    }

    .active-page span {
        color: white !important;
    }

    .active-page {
        border-bottom: 1px solid white;
    }

    .account-icon .dropdown-toggle {
        background-color: transparent !important;
        border: none !important;
        padding-top: 0 !important;
    }

    .main-menu #nav-collapse {
        width: 100%;
        z-index: 20;
        top: 0;
        left: 0;
    }

    .menu-list {
        height: 100%;
        font-size: 14px;
    }

    .menu-list ul .nav-item {
        text-align: center;
    }

    .menu-list ul .nav-item .nav-link {
        padding-left: 0;
        padding-right: 0;
    }

    .account-icon ul {
        left: inherit;
        right: 0 !important;
    }

    @media (min-width: 320px) and (max-width: 991px) {
        .menu-list ul .nav-item {
            font-size: 18px;
            text-align: left;
        }

        .account-icon .dropdown-toggle {
            padding-left: 0;
        }

        .menu-list {
            background-color: black;
            left: 0;
            height: 100%;
        }

        .main-menu .navbar-collapse .navbar-toggler {
            z-index: 220;
        }

        .main-menu .navbar-collapse {
            position: fixed;
            width: 100vw;
            z-index: 10;
            top: 0;
            height: 100%;
            padding-top: 20px;
            overflow: scroll;
            background-color: rgba(205, 176, 6, 0.3);
        }

        .main-menu .navbar-toggler[aria-expanded="true"] {
            z-index: 21;
            position: fixed;
            top: 0;
            right: 0;
            width: 16.5%;
            background: linear-gradient(180deg, rgba(248, 209, 8, 1) 0%, rgb(248, 209, 8) 35%, rgba(155, 115, 51, 1) 100%);
            height: 60px;
            font-size: 32px;
            border: none;
            border-radius: 0;

        }

        .main-menu .navbar-toggler[aria-expanded="false"] {
            font-size: unset;
            border: 1px solid rgb(165, 125, 44) !important;
            z-index: 50;
            margin-top: 15px;
            margin-left: 8px;
        }
    }
</style>

<style scoped>
    .green-color {
        color: #63bb55 !important;
    }

    .login-btn {
        border: 1px solid rgba(211, 182, 9, 0.5);
        padding: 7px 0;
    }

    .login-btn:hover {
        box-shadow: 0 0 7px 2px #ffe0036e !important;
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.05);
        }
        100% {
            transform: scale(1);
        }
    }

    .pulse {
        animation: pulse 3s infinite;
        animation-timing-function: linear;
    }

    .pulse:hover {
        animation: unset;
    }

    .icon {
        padding-bottom: 2px;
    }

    .login-icon {
        margin-top: 17px;
        width: 40px;
        height: 34px;
        object-fit: fill;
    }

    .middle-logo-container img {
        height: 40px;
    }

    .navbar-container {
        background-color: #0b1112;
        height: 65px;
    }

    .mobile-header-menu {
        position: absolute;
        top: 0;
        left: 0;
    }

    .search-mobile {
        right: 100px;
    }

    .accout-mobile {
        cursor: pointer;
    }

    .logout-btn {
        cursor: pointer;
        border-bottom: 1px solid transparent;
    }

    .logout-btn:hover {
        border-bottom: 1px solid white;
        transition: 1s;
    }

    .fa-search {
        font-size: 20px;
    }

    .green-color {
        color: #367a2b;
    }

    .account-info:hover > img {
        box-shadow: 0 0 9px 0 gray !important;
        background-color: rgba(128, 128, 128, 0.2);
        border-radius: 50%;
    }
    .account-info:hover{
      border-bottom: 1px solid #ffffff;
    }

    @media (min-width: 320px) and (max-width: 1530px) {
        .middle-logo-container img {
            bottom: -35px;
        }

        .menu-list {
            height: 100%;
            font-size: 12px;
        }
    }

    @media (min-width: 992px) and (max-width: 1400px) {
        .casino-in-live {
            display: none;
        }
    }

</style>
