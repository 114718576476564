<template>
    <div class="bonus-container py-4 col-12 col-sm-10 col-md-9 mx-auto">
        <div class="my-5">
            <h1 class="site-title text-center page-title white-color">Бонусы</h1>
        </div>
        <div class="row mx-0 bonuses-container" v-if="bonuses">
            <div class="col-12 col-sm-12 col-md-6" v-for="(bonusGroup, indx) in bonuses" :key="indx">
                <div class="col-12 bonus-item text-white pb-3" v-for="(bonus, idx) in bonusGroup"
                     :key="idx + 'first_column'">
                    <div class="heading col-12 px-0">
                        <div class="row mx-0 py-2 py-xl-0">
                            <div class="col-12 col-xl-9 row mx-0 px-0 justify-content-lg-start">
                                <div class="col-5 col-sm-3 col-md-5">
                                    <img :src="getIcon(bonus.image)" :alt="bonus.title" class="bonus-img pt-1">
                                </div>
                                <div class="col-7 col-sm-9 col-md-7 bonus-heading">
                                    <h4 class="">{{bonus.title}}</h4>
                                </div>
                            </div>
                            <div class="col-12 col-xl-3 d-none d-xl-block">
                                <div class="auto-bonus text-center" v-if="bonus.auto">
                                    <img :src="check" alt="LentaSlot Bonuses" class="d-block my-2 mx-auto">
                                    <span class="">Авто бонус</span>
                                </div>
                            </div>
                            <div class="d-xl-none col-12 pb-1 px-0">
                                <img :src="check" alt="LentaSlot Bonuses">
                                <span class="ml-3 v-sub">Авто бонус</span>
                            </div>
                            <div class="col-12 px-0 mt-2 mt-lg-0" v-if="bonus.ect !== ''">
                                <p class="text-left text-center orange-color">{{bonus.ect}}</p>
                            </div>
                            <div class="mt-3 px-1 px-lg-3 bonus-desc" v-html="bonus.text">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 justify-content-center w-100" v-else>
            <i class="fa fa-spinner fa-spin fa-fw my-5 orange-color"></i>
        </div>

    </div>
</template>

<script>
    const check = require('@/../public/images/icons/bonus/check.png')
    export default {
        data() {
            return {
                check
            }
        },
      computed: {
        bonuses() {
          return this.$store.getters['getBonuses']
        }
      },
      methods: {
        getIcon(icon) {
          return require('@/../public/images/icons/bonus/' + icon)
        }
      }
    }
</script>

<style scoped>
    .bonus-heading {
        display: flex;
        align-items: center;
    }

    .v-sub {
        vertical-align: sub;
    }

    .auto-bonus {
        height: 65px;
        width: 120px;
        background-color: rgba(60, 65, 103, 0.3);;
        float: right;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
    }

    .bonus-item {
        background-color: rgba(62, 101, 129, 0.22);
        border-radius: 10px;
        font-size: 15px;
        margin-bottom: 25px;
    }

    .bonus-img {
        transition: 1s;
        width: 120px;
        height: 120px;
        object-fit: none;
    }

    .bonus-img:hover {
        transform: rotate(10deg);
    }

    .bonus-desc {
        font-size: 14px;
    }

    @media (min-width: 320px) and (max-width: 1366px) {
        h4 {
            font-size: 16px;
        }
    }
</style>
