<template>
    <b-modal v-if="canshowPopup && unreadMessages.length"
             v-model="canshowPopup"
             title="У вас новое сообщение!"
             :hide-footer="true"
             :no-close-on-backdrop="true"
             :no-close-on-esc="true"
             :hide-header-close="true">
        <div class="popup-body row">

            <div class="row w-100 msg-item my-2 px-0 mx-0 mb-3" v-if="unreadMessages.length >= 5">
              <div class="col-12">
                  <div class="row mx-0 justify-content-end mt-2">
                    <button class="btn-sm Btn btn-info markAsReadBtn position-absolute pr-3"
                            @click.prevent="markAsReadAll()">
                      Отметить все как прочтенное
                    </button>
                  </div>
                </div>
            </div>

            <div class="row w-100 msg-item my-2 px-0 mx-0" v-for="(msg, idx) in unreadMessages" :key="idx">
                <div class="col-12">
                    <b-col cols="12" class="message-text-container text-white" v-html="msg.message"></b-col>
                    <div class="row mx-0 justify-content-end mt-2">
                        <button class="btn btn-sm btn-custom markAsReadBtn orange-color position-absolute pr-3"
                                @click.prevent="markAsRead(msg.id)">
                            Отметить как прочитанное
                        </button>
                    </div>
                    <hr class="mt-5 mb-1">
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    export default {
        name: 'Popup',
        data() {
            return {
                isClosing: false,
                canshowPopup: true
            }
        },
        computed: {
            unreadMessages() {
                return this.$store.getters['getUnreadMessages']
            }
        },
        mounted() {
            if (this.unreadMessages.length) {
                this.canshowPopup = true
            }
        },
        methods: {
            closePopup() {
                this.canshowPopup = false
            },
            markAsRead(id) {
                this.axios.get(
                    this.$_config.baseUrl + '/Api/MessageViewed/' + id
                ).then(() => {
                    this.$store.dispatch('fetchMessages')
                }).catch(err => {
                    if (err) {
                        this.$store.dispatch('fetchMessages')
                    }
                })
            },
            markAsReadAll() {
              this.axios.get(
                  this.$_config.baseUrl + '/Api/MessageViewedAll'
              ).then(() => {
                this.$store.dispatch('fetchMessages')
              }).catch(err => {
                if (err) {
                  this.$store.dispatch('fetchMessages')
                }
              })
            }
        }
    }
</script>

