import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/store'
import Home from '@/components/Home'
import Register from '@/components/user/Registration'
import Login from '@/components/user/Login'
import ForgotPassword from '@/components/user/ForgotPassword'
import Account from '@/components/user/Account'
import Deposit from '@/components/payment/Deposit'
import Withdraw from '@/components/payment/Withdraw'
import Bonuses from '@/components/Bonuses'
import TheGame from '@/components/games/TheGame'
 import Terms from '@/components/Terms'
import UserLevels from "@/components/UserLevels";

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            name: 'home',
            path: '/',
            props: true,
            component: Home
        },
        {
            name: 'registration',
            path: '/registration',
            component: Register,
            beforeEnter: (to, from, next) => {
                if (Store.getters['userData']) {
                    return next('/')
                }
                return next()
            }
        },
        {
            name: 'login',
            path: '/login',
            component: Login,
            beforeEnter: (to, from, next) => {
                if (Store.getters['userData']) {
                    return next('/')
                }
                return next()
            }
        },
        {
            name: 'forgotPassword',
            path: '/forgotPassword',
            component: ForgotPassword,
            beforeEnter: (to, from, next) => {
                if (Store.getters['userData']) {
                    return next('/')
                }
                return next()
            }
        },
        {
            name: 'account',
            path: '/account/:placeName',
            prop: true,
            component: Account
        },
        {
            name: 'deposit',
            path: '/deposit',
            component: Deposit
        },
        {
            name: 'withdraw',
            path: '/Withdraw',
            component: Withdraw
        },
        {
            name: 'bonuses',
            path: '/Bonuses',
            component: Bonuses
        },
        {
            name: 'TheGame',
            path: '/Game/:gameName',
            props: true,
            component: TheGame,
            beforeEnter: (to, from, next) => {
                if (Store.getters['userData'] === null || typeof Store.getters['userData'] === 'undefined') {
                    Store.dispatch('fetchUserData').then((resp) => {
                        if (resp.userData) {
                            return next()
                        }
                        return next('/login')
                    })
                }
                return next()
            }
        },
        {
            name: 'terms',
            path: '/Terms',
            component: Terms
        },
        {
            name: 'UserLevels',
            path: '/Levels',
            props: true,
            component: UserLevels,
            meta: {
                title: 'Уровни игроков'
            }
        }
    ]
})

