<template>
    <b-carousel :interval="3000" indicators>
        <b-carousel-slide v-for="(slide, index) in slides" :img-src="slide" :key="index"
                          :img-alt="'LentaSlot - Online Casino'"></b-carousel-slide>
    </b-carousel>
</template>

<script>
  export default {
    data() {
      return {
        slides: {
          slide_1: require('@/../public/images/slider/slide_2.jpg'),
          slide_2: require('@/../public/images/slider/slide_3.jpg'),
          slide_3: require('../../../public/images/slider/cashback_12.png'),
          slide_4: require('@/../public/images/slider/slide_4.jpg'),
          slide_5: require('@/../public/images/slider/slide_5.jpg')
        }
      }
    }
  }
</script>

<style>
    .carousel-indicators li {
        border-radius: 50%;
        height: 12px !important;
        width: 12px !important;
        border: 1px solid #f6d307d9 !important;
        background: transparent !important;
    }

    .carousel-indicators li.active {
        background-image: linear-gradient(#f6d307d9, #96731e) !important;
    }
</style>
