<template>
    <div class="account-container col-12 py-2 pt-lg-5">
        <div class="col-12 mx-auto text-center my-5">
            <h1 class="white-color">Ваш профиль</h1>
        </div>
        <div class="col-12 col-sm-10 col-md-8 col-lg-7 mt-3 mt-lg-5 mx-auto px-0">
            <b-tabs content-class="mt-0 mt-lg-3" class="tab account-tab px-0">
                <b-tab class="tab-item" title="Профиль"
                       :active="activeTab==='profile'"
                       @click="onTabChange('profile')"
                >
                    <profile></profile>
                </b-tab>

                <b-tab class="tab-item" title="Письма"
                       :active="activeTab==='messages'"
                       @click="onTabChange('messages')"
                >
                    <messages></messages>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import Profile from '@/components/user/Profile'
import Messages from '@/components/user/Messages'

export default {
    data() {
      return {
        activeTab : 'profile'
      }
    },
    props: ['placeName'],
    components: {
        Profile,
        Messages
    },
    methods :{
      onTabChange (toRoute) {
        if (this.activeTab !== toRoute) {
          this.$router.push({ name: 'account', params: {placeName : toRoute} })
        }
      }
    },
    computed: {
        userData() {
            return this.$store.getters['userData']
        },
        siteSettings () {
            return this.$store.getters.getMainSettings
        }
    },
    watch: {
        userData(newValue) {
            if (newValue) {
                this.$router.push({name: 'home'})
            }
        },
        $route (to) {
          if (to && to.params && to.params.placeName) {
            this.activeTab = to.params.placeName
          }
        }
    },
    mounted() {
        setTimeout(() => {
          if (!this.userData) {
            return this.$router.push({name: 'home'})
          }
        }, 2500)
        if (typeof this.placeName !== "undefined") {
          this.activeTab = this.placeName
        } else if (this.$route.params && this.$route.params.placeName) {
          this.activeTab = this.$route.params.placeName
          this.$router.push({ name: 'account', params: {placeName : this.$route.params.placeName} })
        } else {
          this.$router.push({ name: 'account', params: {placeName : 'profile'} })
        }

    }
}
</script>

<style>
    .account-tab .nav-tabs {
        border: none !important;
        margin-bottom: 30px;
        justify-content: center;
    }

    .account-tab .nav-tabs .nav-item {
        width: 33% !important;
        border: none !important;
    }

    .account-tab .nav-tabs .nav-item .nav-link {
        color: white !important;
        background-color: rgba(124, 128, 136, 0.3);
        box-shadow: 0 0 10px 2px rgba(124, 128, 136, 0.3)  !important;
        border: none;
        border-radius: 30px;
        text-align: center;
    }

    .account-tab .nav-tabs .nav-item .nav-link.active {
        box-shadow: 0 0 10px 2px #ffe0036e  !important;
    }

    .account-tab .nav-tabs .nav-item:first-child .nav-link {
        margin-right: 10px;
    }

    .account-tab .nav-tabs .nav-item:last-child .nav-link {
        margin-left: 10px;
    }

    .account-tab .nav-tabs .nav-item:first-child .nav-link::before {
        content: url("../../../public/images/icons/account/profile.png");
        margin-right: 10px;
    }

    .account-tab .nav-tabs .nav-item:last-child .nav-link::before {
        content: url("../../../public/images/icons/account/message.png");
        margin-right: 10px;
    }

    .recover-form input {
        border-radius: 20px !important;
        border: none;
    }

    @media (min-width: 320px) and (max-width: 768px) {
        .account-tab .nav-tabs .nav-item {
            width: 33% !important;
            margin-bottom: 10px;
        }
        .account-tab .nav-tabs .nav-link:before {
              display: none;
          }
    }
</style>
