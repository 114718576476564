<template>
    <div class="row recover-by-email-container mx-0">

        <b-row class="w-100 mt-3 mx-auto">
            <b-form class="col-12 col-md-10 col-lg-8 mb-0 mb-sm-5 mx-auto recover-form px-0" @submit.prevent="Restore"
                    autocomplete="off">

                <b-form-group class="mb-4">
                    <div class="col-4 d-inline-block text-right">
                        <label class="col-form-label white-color">Ваш e-mail</label>
                    </div>
                    <div class="col-8 d-inline-block">
                        <b-form-input v-model="restoreEmail.email" type="email" aria-label="email" required></b-form-input>
                    </div>
                </b-form-group>

                <b-form-group>
                    <div class="col-4 d-inline-block vertical-top text-right">
                        <label class="col-form-label white-color">Введите символы</label>
                        <captcha ref="pass_change_email_captcha" :captcha_key="'password_restore'"></captcha>
                    </div>
                    <div class="col-8 d-inline-block">
                        <b-form-input v-model="restoreEmail.password_restore_captcha" aria-label="password_restore_captcha" required></b-form-input>
                        <span class="gray-color captcha-hint">Введите символы которые видите на картинке</span>
                    </div>
                </b-form-group>

                <div class="col-12 text-center mx-auto mt-4">
                    <span class="white-color hint"> На вашу почту прийдет письмо с инструкцией для смены пароля </span>
                </div>

                <b-row class="w-100 mt-5 mx-auto">
                    <alert :message="response"></alert>
                    <div class="clearfix"></div>
                </b-row>

                <b-row class="col-12">
                    <button class="btn btn-orange px-3 mx-auto" type="submit" :disabled="isSubmitting">
                        Отправить письмо
                    </button>
                </b-row>

            </b-form>
        </b-row>
    </div>
</template>

<script>
    import Captcha from '@/components/Captcha'
    import Alert from '@/components/Alert'

    export default {
        components: {
            Captcha,
            Alert
        },
        data() {
            return {
                show: false,
                response: null,
                isSubmitting: false,
                restoreEmail: {
                    email: '',
                    password_restore_captcha: ''
                },
                passChangeEmailResponse: null,
                passChangeEmailSubmitting: false
            }
        },
        methods: {
            Restore() {
                this.response = {status: 'loading'}
                this.isSubmitting = true
                this.axios.post(
                    this.$_config.baseUrl + '/Api/restorePasswordRequest/', {
                        email: this.restoreEmail.email,
                        password_restore_captcha: this.restoreEmail.password_restore_captcha
                    },
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.isSubmitting = false
                    this.response = data
                    if (data.status === 'ok') {
                        this.show = true
                        this.restoreEmail.password_restore_captcha = ''
                    }
                    this.$refs.pass_change_email_captcha.updateCaptcha()
                }).catch((err) => {
                    if (err) {
                        this.isSubmitting = false
                        this.response = {status: 'error', msg: err.response.data.errors}
                    }
                })
            }
        }
    }
</script>

