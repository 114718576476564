<template>
    <b-modal id="confirmPhoneModal" title="Подтверждение номер телефона" size="lg" :hide-footer="true">
        <form @submit.prevent="confirmPhone" class="phone-confirmation-form" autocomplete="off" v-if="userData">

            <b-form-group>

                <div class="row mx-0">
                    <div class="col-4 col-sm-3 col-md-2 px-0">
                        <label class="mx-0 col-form-label text-right white-color">
                            Ваш номер телефона <span class="red-color">*</span>
                        </label>
                    </div>
                    <div class="col-8 col-sm-9">
                        <b-form-input v-model="phoneConfirmPayload.phone" required v-mask="'+#(###)-###-##-##'"></b-form-input>
                    </div>
                </div>
            </b-form-group>

            <b-form-group>
                <div class="row mx-0">
                    <div class="col-4 col-sm-3 col-md-2 px-0">
                        <label class="col-form-label white-color text-right">Введите символы</label>
                        <captcha ref="phone_confirm_captcha" :captcha_key="'phone_confirm_captcha'"></captcha>
                    </div>
                    <div class="col-8 col-sm-9">
                        <b-form-input v-model="phoneConfirmPayload.captcha" required></b-form-input>
                        <span class="white-color captcha-hint">Введите символы которые видите на картинке</span>
                    </div>
                </div>
            </b-form-group>

            <Alert :message="smsSendResponse"></Alert>

            <div class="form-group row w-100" v-if="!confirmCodeSent">

                <div class="col-4 col-lg-8"></div>
                <div class="col-8 col-lg-4">
                    <button name="submit" type="submit" class="btn btn-orange w-100"
                            :disabled="isSmsSending" @click.prevent="sendSmsCode">
                        Отправить SMS
                    </button>
                </div>
            </div>

            <div class="col-12 px-0" v-if="confirmCodeSent">
                <b-form-group>
                    <div class="row mx-0">
                        <div class="col-4 col-sm-3 col-md-2 px-0 text-right">
                            <label class="mx-0 col-form-label text-right white-color">Код</label>
                        </div>
                        <div class="col-8 col-sm-9">
                            <b-form-input v-model="phoneConfirmPayload.sms_code" required></b-form-input>
                        </div>
                    </div>
                </b-form-group>

                <Alert :message="phoneConfirmResponse"></Alert>

                <div class="form-group row w-100">

                    <div class="col-4 col-lg-8"></div>
                    <div class="col-8 col-lg-4">
                        <button name="submit" type="submit" class="btn btn-orange w-100" :disabled="isFormSubmitting">
                            Подтвердить
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
</template>


<script>
    import Captcha from '@/components/Captcha'
    import Alert from '@/components/Alert'

    export default {
        props: ['userData'],
        name: 'ConfirmPhone',
        data() {
            return {
                confirmCodeSent: false,
                smsSendResponse: null,
                isSmsSending: false,
                isFormSubmitting: false,
                phoneConfirmResponse: null,
                phoneConfirmPayload: {
                    phone: '',
                    captcha: '',
                    sms_code: ''
                }
            }
        },
        components: {
            Alert,
            Captcha
        },
        methods: {
            sendSmsCode() {
                this.smsSendResponse = {status: 'loading'}
                this.isSmsSending = true
                let queryString = `phone=${this.phoneConfirmPayload.phone}&captcha=${this.phoneConfirmPayload.captcha}`
                this.axios.post(
                    this.$_config.baseUrl + '/Api/sendConfirmationSms', queryString,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.smsSendResponse = data
                    if (data.status === 'ok') {
                        this.confirmCodeSent = true
                    }
                    this.phoneConfirmPayload.captcha = ''
                    this.isSmsSending = false
                }).catch(err => {
                    console.log(err)
                    this.smsSendResponse = {
                        status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'
                    }
                    this.phoneConfirmPayload.captcha = ''
                    this.isSmsSending = false
                })
                this.$refs.phone_confirm_captcha.updateCaptcha()
            },
            confirmPhone() {
                this.phoneConfirmResponse = {status: 'loading'}
                this.isFormSubmitting = true
                let queryString = `phone_confirmation_captcha=${this.phoneConfirmPayload.captcha}&sms_code=${this.phoneConfirmPayload.sms_code}`

                this.axios.post(
                    this.$_config.baseUrl + '/Api/PhoneConfirmRequest', queryString,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.phoneConfirmResponse = data
                    this.isFormSubmitting = false
                    if (data.status === 'ok') {
                        setTimeout(() => {
                            this.$root.$emit('bv::hide::modal', 'confirmPhoneModal')
                        }, 4000)
                        this.$store.dispatch('fetchUserData')
                    }
                }).catch(err => {
                    console.log(err)
                    this.phoneConfirmResponse = {status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'}
                    this.isFormSubmitting = false
                })
                this.$refs.phone_confirm_captcha.updateCaptcha()
            }
        },
        mounted() {
            if (this.userData) {
                this.phoneConfirmPayload.phone = this.userData.phone
            }
        }

    }
</script>

<style scoped>
    .phone-confirmation-form input {
        border-radius: 20px !important;
        border-color: #b9c0c5;
    }
</style>
