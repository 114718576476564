<template>
    <div class="game-item position-relative mb-5 mx-3" @click.prevent.stop="gotoGameView">
        <img :data-src="getImageUrl" :src="getImageUrl" class="game-img w-100 h-100" :alt="game.name">
        <div class="overlay w-100 h-100 text-center pt-2">
            <div class="game-info w-100">
                <h6 class="my-2 px-1 game-title"> {{game.name}} </h6>
                <a class="btn btn-orange playbtn w-75 mx-auto d-none d-lg-block" @click.prevent.stop="gotoGameView">
                    ИГРАТЬ
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import Config from '@/config'

    export default {
        props: ['game'],
        name: 'GameItemPreview',

        computed: {
            getImageUrl() {
                return Config.baseUrl + '/' + this.game.picture
            }
        },
        methods: {
            gotoGameView() {
                this.$router.push({
                    name: 'TheGame',
                    params: {
                        gameName: this.game.name,
                        gameId: this.game.id,
                        game: this.game,
                        provider: this.game.system,
                        isLive: this.game.isLive,
                        restoring : false
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .playbtn,
    .game-title {
        font-size: 14px;
    }

    .game-info {
        display: table-cell;
        vertical-align: middle;
    }

    .game-img {
        border-radius: 8px;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
        background-image: url("../../../public/images/border-bg.png");
        background-size: 100% 100%;
        border-radius: 8px;
        display: none;
    }

    .game-item:hover > .overlay {
        display: table;
        overflow: hidden;
    }
</style>
