<template>
    <div class="col-12 col-lg-10 mx-auto mx-0">
        <div class="row justify-content-center pb-3">
            <div v-for="(feature, idx) in feaures" :key="idx" class="col-12 col-sm-6 col-md-4 text-center feature" v-bind:class="'bg_'+idx">
                <div class="col-12">
                    <img :src="feature.img" :alt="feature.title" class="feature-img mw-100">
                </div>
                <div class="col-12 feature-desc">
                    <h5 class="white-color">{{feature.title}}</h5>
                    <span v-bind:style="'color: ' + feature.textColor">{{feature.text}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                feaures: {
                    bigWin: {
                        img: require('@/../public/images/mainFeatures/big-win.png'),
                        title: 'Большой выигрыш',
                        text: 'Выигрыш каждый день',
                        textColor: '#006a97'
                    },
                    withdraw: {
                        img: require('@/../public/images/mainFeatures/withdraw.png'),
                        title: 'Круглосуточное снятие',
                        text: 'Без комиссии',
                        textColor: '#742753'
                    },
                    interestingGames: {
                        img: require('@/../public/images/mainFeatures/interesting-games.png'),
                        title: 'Самые интересные игры',
                        text: 'Большой выбор',
                        textColor: '#462aab'
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .feature-desc span {
        font-size: 14px;
        font-weight: 300;
    }

    .feature-img {
        height: 200px;
    }

    .feature {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        opacity: 0.6;
    }
</style>
