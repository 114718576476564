<template>
    <div class="row recover-by-call-container mx-0">

        <b-row class="w-100 mt-3 mx-auto">
            <b-form class="col-12 col-md-10 col-lg-8 mb-0 mb-sm-5 mx-auto recover-form px-0"
                    @submit.prevent="Restore"
                    autocomplete="off">

                <b-form-group class="mb-4" id="phone-input-group" label-for="phone-input">
                    <div class="col-4 d-inline-block text-right">
                        <label class="col-form-label white-color">Bаш номер телефона</label>
                    </div>
                    <div class="col-8 d-inline-block">
                        <the-mask mask="+#(###)-###-##-##"
                                  id="phone-input"
                                  class="form-control"
                                  v-model="form.phone"
                                  aria-label="phone"
                                  required>
                        </the-mask>
                    </div>
                </b-form-group>

                <b-form-group>
                    <div class="col-4 d-inline-block vertical-top text-right ">
                        <label class="col-form-label white-color">Введите символы</label>
                        <captcha ref="password_change_sms" :captcha_key="'password_change_sms'"></captcha>
                    </div>
                    <div class="col-8 d-inline-block">
                        <b-form-input v-model="form.captcha" required aria-label="captcha"></b-form-input>
                        <span class="captcha-hint gray-color">Введите символы которые видите на картинке</span>
                    </div>
                </b-form-group>

                <b-row class="w-100 mt-5 mx-auto">
                    <alert :message="response"></alert>
                    <div class="clearfix"></div>
                </b-row>

                <div class="col-12 px-0" v-if="show">
                    <b-form-group class="mb-4">
                        <div class="col-4 d-inline-block text-right">
                            <label class="col-form-label white-color">
                              Последние 4 цифры номера  с которого поступил звонок
                            </label>
                        </div>
                        <div class="col-8 d-inline-block">
                            <b-form-input v-model="form.call_code"
                                          type="text"
                                          aria-label="call_code"
                                          required>
                            </b-form-input>
                        </div>
                    </b-form-group>

                    <b-form-group class="mb-4">
                        <div class="col-4 d-inline-block text-right">
                            <label class="col-form-label white-color">Пароль</label>
                        </div>
                        <div class="col-8 d-inline-block">
                            <b-form-input v-model="form.new_password"
                                          aria-label="new_password"
                                          required
                                          type="password">
                            </b-form-input>
                        </div>
                    </b-form-group>

                    <b-form-group class="mb-4">
                        <div class="col-4 d-inline-block text-right">
                            <label class="col-form-label white-color">Пароль еще раз</label>
                        </div>
                        <div class="col-8 d-inline-block">
                            <b-form-input v-model="form.new_password_confirm"
                                          aria-label="new_password_confirm"
                                          type="password"
                                          required>
                            </b-form-input>
                        </div>
                    </b-form-group>

                </div>

                <b-row class="col-12">
                    <button class="btn btn-orange mx-auto px-3" type="submit" :disabled="isSubmitting">
                        Звонить на номер
                    </button>
                </b-row>

            </b-form>
        </b-row>

    </div>
</template>

<script>
    import Captcha from '@/components/Captcha'
    import Alert from '@/components/Alert'

    export default {
        data() {
            return {
                response: null,
                isSubmitting: false,
                form: {
                    phone: '',
                    captcha: '',
                    call_code: '',
                    new_password: '',
                    new_password_confirm: ''
                },
                show: false
            }
        },
        components: {
            Captcha,
            Alert
        },
        methods: {
            Restore() {
                this.response = {status: 'loading'}
                this.isSubmitting = true

                if (this.show) {
                    let sendPayload = {
                        phone: this.form.phone,
                        call_code: this.form.call_code,
                        new_password: this.form.new_password,
                        password_restore_captcha: this.form.captcha,
                        new_password_confirm: this.form.new_password_confirm
                    }

                    let queryString = this.$_config.makeQueryStringFromObject(sendPayload)

                    this.axios.post(
                        this.$_config.baseUrl + '/Api/restorePasswordAfterCall', queryString,
                        {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }
                    ).then(({data}) => {
                        this.isSubmitting = false
                        this.response = data
                        if (data.status === 'ok') {
                            this.show = false
                            this.form.captcha = ''
                            this.$refs.password_change_sms.updateCaptcha()
                            setTimeout(() => this.$router.push({name: 'login'}), 3000)
                        }
                    }).catch((err) => {
                        if (err) {
                            this.isSubmitting = false
                            this.form.captcha = ''
                            this.$refs.password_change_sms.updateCaptcha()
                            this.response = {status: 'error', msg: err.response.data.errors}
                        }
                    })
                } else {
                    this.axios.post(
                        this.$_config.baseUrl + '/Api/callForRestorePassword',
                        `phone=${this.form.phone}&captcha=${this.form.captcha}`,
                        {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }
                    ).then(({data}) => {
                        this.isSubmitting = false
                        this.response = data
                        if (data.status === 'ok') {
                            this.show = true
                            this.form.captcha = ''
                        }
                        this.$refs.password_change_sms.updateCaptcha()
                    }).catch((err) => {
                        if (err) {
                            this.isSubmitting = false
                            this.$refs.password_change_sms.updateCaptcha()
                            this.form.captcha = ''
                            this.response = {status: 'error', msg: err.response.data.errors}
                        }
                    })
                }
            }
        }
    }
</script>
