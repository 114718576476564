<template>
    <div class="registration-container pt-4 px-0 white-color col-12">

        <div class="page-title white-color text-center">
            <h1 class="mt-5">Регистрация</h1>
        </div>

        <div class="row mx-0">
            <div class="col-12 row mx-0 mb-4">
                <div class="col-12 col-sm-9 mx-auto text-center">
                    <button class="btn px-3 btn-orange" @click.prevent="vkAuth">
                        Регистрация/Вход через ВК <i class="fa fa-vk"></i>
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-9 col-xl-5 mx-auto px-0 py-5">
                <b-form class="registration-form" @submit.prevent="Registration" autocomplete="off">
                    <div class="row mx-0">
                        <div class="input-group mb-4">
                            <div class="col-3">
                                <label class="col-form-label mr-3">Логин*</label>
                            </div>
                            <div class="col-9">
                                <input class="form-control" required type="text" v-model="payload.user_login"
                                       aria-label="login">
                            </div>
                        </div>
                    </div>

                    <div class="row mx-0">
                        <div class="input-group mb-4">
                            <div class="col-3">
                                <label class="col-form-label mr-3">Ваше имя*</label>
                            </div>
                            <div class="col-9">
                                <input class="form-control" required type="text" v-model="payload.user_name"
                                       aria-label="name">
                            </div>
                        </div>
                    </div>

                    <div class="row mx-0">
                        <div class="input-group mb-4">
                            <div class="col-3">
                                <label class="col-form-label mr-3">E-mail*</label>
                            </div>
                            <div class="col-9">
                                <input class="form-control" type="email" required v-model="payload.user_email"
                                       aria-label="email">
                            </div>
                        </div>
                    </div>

                    <div class="row mx-0">
                        <div class="input-group mb-4">
                            <div class="col-3">
                                <label class="col-form-label mr-3">Номер телефона*</label>
                            </div>
                            <div class="col-9">
                                <the-mask mask="+#(###)-###-##-##" class="form-control" v-model="payload.user_phone"
                                          required type="text" aria-label="phone"></the-mask>
                            </div>
                        </div>
                    </div>

                    <div class="row mx-0">
                        <div class="input-group mb-4">
                            <div class="col-3">
                                <label class="col-form-label mr-3">Пароль*</label>
                            </div>
                            <div class="col-9">
                                <input type="password" class="form-control" required v-model="payload.user_password"
                                       aria-label="password">
                                <small class="form-text text-muted orange-color">Придумайте пароль</small>
                            </div>
                        </div>
                    </div>

                    <div class="row mx-0">
                        <div class="input-group">
                            <div class="col-3">
                                <label class="col-form-label mr-3">Промокод</label>
                            </div>
                            <div class="col-9">
                                <input type="text" class="form-control" v-model="payload.promo_code"
                                       aria-label="promo-code">
                                <small class="form-text text-muted">Если нет промокода, оставьте поле пустым</small>
                            </div>
                        </div>
                    </div>

                    <div class="row mx-0">
                        <div class="col-0 col-lg-3 px-0"></div>
                        <div class="col-12 col-lg-9 mt-2">
                            <alert :message="response"></alert>
                            <div class="clearfix"></div>
                        </div>
                    </div>

                    <div class="row mx-0">
                        <div class="col-0 col-lg-3 px-0"></div>
                        <div class="col-12 col-lg-9">
                            <small class="form-text text-muted my-3">Поля помеченные * обязательны к заполнению</small>
                        </div>
                    </div>

                    <div class="row mx-0 my-4">
                        <div class="col-0 col-sm-3 px-0"></div>
                        <div class="col-12 col-sm-9 text-center">
                            <button class="btn btn-orange w-75" @click.prevent="Registration" :disabled="isSubmitting">
                                Регистрация
                            </button>
                        </div>
                    </div>

                    <div class="row mx-0">
                        <div class="col-0 col-sm-3 px-0"></div>
                        <div class="col-12 col-sm-9">
                            <div class="row w-75 mx-auto">
                                <label class="col-form-label mt-2 mx-auto">Уже есть профиль?</label>
                                <router-link class="btn  btn-green w-100" :to="{ path: '/login' }">
                                    Войти!
                                </router-link>
                            </div>
                        </div>
                    </div>
                </b-form>
            </div>
        </div>

    </div>
</template>

<script>
    import Alert from '@/components/Alert'

    export default {
        name: 'RwvRegister',
        components: {
            Alert
        },
        data() {
            return {
                response: null,
                isSubmitting: false,
                payload: {
                    user_login: '',
                    user_email: '',
                    user_password: '',
                    user_phone: '',
                    promo_code: '',
                    user_currency: 1,
                    user_name: ''
                }
            }
        },
        methods: {
            vkAuth(){
                this.response = {status: 'loading'}
                this.isSubmitting = true
                this.axios.get(
                    this.$_config.baseUrl + '/Api/authViaVk',
                ).then((resp) => {
                    if (resp.data && resp.data.status) {
                        if (resp.data.status === "ok") {
                            window.location =  resp.data.data.url;
                            return true
                        } else {
                            this.response = {status: resp.data.status, msg: resp.data.msg}
                            this.isSubmitting = false
                            return false
                        }
                    }
                }).catch(err => {
                    if (err) {
                        console.log(err)
                    }
                })
            },
            Registration() {
                this.response = {status: 'loading'}
                this.isSubmitting = true
                this.axios.post(
                    this.$_config.baseUrl + '/Api/registration',
                    this.payload, {
                        withCredentials: true
                    }
                ).then(({data}) => {
                    this.isSubmitting = false
                    this.response = data
                    if (data.status === 'ok') {
                        if(data.data.postMessage == 1){
                          window.ReactNativeWebView.postMessage(JSON.stringify({
                            user: data.data.id,
                            type: "fetch",
                            message: "Success"
                          }))
                        }
                        this.$store.dispatch('fetchUserData')
                        this.$store.dispatch('fetchMainSettings')
                    }
                }).catch((err) => {
                    if (err) {
                        this.isSubmitting = false
                        this.response = {status: 'error', msg: err.response.data.errors}
                    }
                })
            }
        },
        computed: {
            isAuthorized() {
                return this.$store.getters['userData']
            }
        },
        watch: {
            isAuthorized(newValue) {
                if (newValue) {
                    this.$router.push({name: 'home'})
                }
            }
        },
        mounted() {
            if (this.isAuthorized) {
                this.$router.push({name: 'home'})
            }
        }
    }
</script>

<style scoped>
    .col-form-label {
        font-size: 14px;
    }

    .registration-form input,
    .registration-form select {
        border-radius: 20px !important;
        height: 35px;
        border: none;
    }

    .registration-form input:focus,
    .registration-form select:focus {
        box-shadow: 0 0 10px 3px rgba(145, 157, 243, 0.5) !important;
    }

    input[type="password"]:active,
    input[type="password"]:focus {
        box-shadow: 0 0 10px 3px rgba(170, 83, 23, 0.5) !important;
    }
</style>
