<template>
    <b-modal id="confirmEmailModal" title="Подтверждение почты" size="lg" :hide-footer="true">

        <form @submit.prevent="confirmEmail" class="email-confirmation-form" autocomplete="off" v-if="userData">

            <b-form-group>
                <div class="row mx-0">
                    <div class="col-4 col-sm-3 col-md-2 px-0">
                        <label class="mx-0 col-form-label text-right white-color">Адрес Вашей почты</label>
                    </div>
                    <div class="col-8 col-sm-9">
                        <b-form-input v-model="emailConfirmPayload.email" required type="email"></b-form-input>
                    </div>
                </div>
            </b-form-group>

            <b-form-group>
                <div class="row mx-0">
                    <div class="col-4 col-sm-3 col-md-2 px-0">
                        <label class="col-form-label text-right white-color">Введите символы</label>
                        <captcha ref="email_confirmation" :captcha_key="'email_confirmation'"></captcha>
                    </div>

                    <div class="col-8 col-sm-9">
                        <b-form-input v-model="emailConfirmPayload.captcha" required></b-form-input>
                        <span class="white-color captcha-hint">Введите символы которые видите на картинке</span>
                    </div>
                </div>

            </b-form-group>

            <div class="row mx-0">
                <span class="mx-auto my-3 hint white-color">
                    На вашу почту прийдет письмо с инструкцией для смены пароля
                </span>
            </div>

            <Alert :message="emailSendResponse"></Alert>

            <div class="form-group row mx-0">
                <div class="col-0 col-lg-1"></div>
                <div class="col-11 col-lg-4 px-0 mx-2 mt-2 text-right  ">
                    <a class="btn btn-gray mx-auto white-color w-50" @click="closePopup">
                        Отмена
                    </a>
                </div>

                <div class="col-11 col-lg-4 px-0 mx-0 mx-lg-2 mx-auto">
                    <button name="submit" type="submit" class="btn btn-orange white-color w-100 border-0"
                            :disabled="isEmailSending"
                            @click.stop.prevent="sendConfirmationEmail">
                        Отправить письмо
                    </button>
                </div>
            </div>

        </form>
    </b-modal>
</template>

<script>
    import captcha from '@/components/Captcha'
    import Alert from '@/components/Alert'

    export default {
        props: ['userData'],
        name: 'ConfirmEmail',
        data() {
            return {
                emailSendResponse: null,
                isEmailSending: false,
                isFormSubmitting: false,
                emailConfirmPayload: {
                    email: '',
                    captcha: ''
                }
            }
        },
        components: {
            Alert,
            captcha
        },
        methods: {
            closePopup() {
                this.emailConfirmPayload.captcha = ''
                this.$root.$emit('bv::hide::modal', 'confirmEmailModal')
            },
            sendConfirmationEmail() {
                this.emailSendResponse = {status: 'loading'}
                this.isEmailSending = true
                let queryString = `email=${this.emailConfirmPayload.email}&email_confirmation_captcha=${this.emailConfirmPayload.captcha}`
                this.axios.post(
                    this.$_config.baseUrl + '/Api/EmailConfirmRequest/', queryString,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.emailSendResponse = data
                    this.emailConfirmPayload.captcha = ''
                    this.isEmailSending = false
                }).catch(err => {
                    console.log(err.message)
                    this.isEmailSending = false
                    this.emailSendResponse = {
                        status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'
                    }
                })
                this.$refs.email_confirmation.updateCaptcha()
            }
        },
        mounted() {
            if (this.userData) {
                this.emailConfirmPayload.email = this.userData.email
            }
        }

    }
</script>


<style scoped>
    .email-confirmation-form input {
        border-radius: 20px !important;
        border-color: #b9c0c5;
    }

    .hint {
        font-size: 16px;
        color: black;
        font-weight: 600;
    }
</style>
