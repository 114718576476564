<template>
    <div class="footer white-color mx-0 w-100 py-3 py-lg-5">
        <div class="col-12 col-sm-11 col-md-10 mx-auto">
            <div class="col-12 col-md-6 col-lg-4 d-inline-block text-center text-md-left vertical-top">
                <div class="row">
                    <div class="col-12 col-lg-7 mx-auto">
                        <h6 class="orange-color">Наши контакты</h6>
                    </div>
                    <div class="col-12 col-lg-7 mx-auto">
                        <ul class="contacts list-unstyled">

                            <li class="my-2" v-if="socialLinks.vk_manager">
                                <a :href="socialLinks.vk_manager" class="social-link white-color" target="_blank"
                                   rel="noreferrer"> VK manager
                                </a>
                            </li>

                            <li class="my-2"  v-if="socialLinks.tg_manager">
                                <a :href="socialLinks.tg_manager" class="social-link white-color" target="_blank"
                                   rel="noreferrer"> Telegram manager
                                </a>
                            </li>
                            <li class="my-2">
                              <a class="social-link white-color" download :href="$config.baseUrl+'/static/public/application/lentaslot.apk'">
                                Скачать для Андроид
                              </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 d-inline-block text-center text-md-left vertical-top">

                <div class="row">
                    <div class="col-12 col-lg-7 mx-auto">
                        <h6 class="orange-color">Меню</h6>
                    </div>
                    <div class="col-12 col-lg-7 mx-auto">
                        <ul class="footer-menu list-unstyled">
                            <li class="my-2">
                                <router-link :to="{ name: 'home' }" exact class="px-0 d-block">
                                    <span class="footer-menu-item">Главная</span>
                                </router-link>
                            </li>
                            <li class="my-2">
                                <router-link
                                        :to="{ name: 'TheGame', params: {gameName:'KronosWheel', gameId:1, provider: 'WheelOfFortune'}}"
                                        exact class="px-0 d-block">
                                    <span class="footer-menu-item">Колесо</span>
                                </router-link>
                            </li>
                            <li class="my-2">
                                <router-link :to="{ name: 'deposit' }" exact class="px-0 d-block">
                                    <span class="footer-menu-item">Депозит</span>
                                </router-link>
                            </li>
                            <li class="my-2">
                                <router-link :to="{ name: 'withdraw' }" exact class="px-0 d-block">
                                    <span class="footer-menu-item">Выплаты</span>
                                </router-link>
                            </li>
                            <li class="my-2">
                                <router-link :to="{ name: 'terms' }" exact class="px-0 d-block">
                                    <span class="footer-menu-item">Условия</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
            <div class="col-12 col-lg-4 d-inline-block text-center text-md-left mt-4 mt-lg-0 px-5 px-md-0 vertical-top">
                <h6 class="white-color fs-12">Платежные системы</h6>
                <router-link :to="{ name: 'deposit' }">
                    <img :src="require('@/../public/images/payment/payment-methods.png')" alt="Payment Methods"
                         class="payment-methods w-100 my-3"><br>
                </router-link>
                <span class="white-color fs-12 text-md-center ">© 2010-{{thisYear}} LentaSlot. All rights reserved</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    thisYear() {
      return new Date().getFullYear()
    },

    socialLinks() {
      return this.$store.getters.getSocialLinks
    },
  }
}
</script>
<style scoped>
    .contacts,
    .footer-menu {
        font-size: 14px;
    }

    .footer {
        background-color: #070a0f;
    }

    .fs-12 {
        font-size: 12px;
    }

    .footer-menu-item {
        transition: 0.5s;
        color: white;
    }

    .router-link-active .footer-menu-item
    .footer-menu-item:hover {
        color: #f6d307d9 !important;
    }
</style>
