<template>
    <div>
        <b-modal id="liveTablesModal" title="Выберите стол" size="xl"
                 :hide-footer="true"
                 :no-close-on-esc="true"
                 :no-close-on-backdrop="true"
                 :hide-header-close="true"
                 ref="liveTablesModal"
                 @cancel="goHome"
                 @esc="goHome"
                 @backdrop="goHome"
                 @headerclose="goHome"
        >
            <b-card no-body class="col-12 border-0 text-white bg-transparent px-0" v-if="tables">
                <div class="form-group">
                    <div class="col-md-12 mx-auto my-3 text-center px-0" v-if="tables">
                        <div class="row justify-content-around live-games-list-row no-gutters mx-0">
                            <div class="game-item-col col-6 col-sm-4 col-lg-4" v-for="(table, i) in tables"
                                 v-if="table.isOpen" :key="i">
                                <div class="live-game-item position-relative mb-3 mb-lg-5 mx-1 mx-lg-3"
                                     @click.prevent.stop="gotoPlayLiveGameView(table.lobbyData)"
                                    v-lazy-container="{ selector: 'img', loading: require('@/../public/images/icons/game-images-loader.svg') }">
                                    <img
                                         :data-src="table.dealerAvatar"
                                         :data-error="require('@/../public/images/live/'+table.type+'.jpg')"
                                         @error="replaceByDefault(table.type)"
                                         :src="table.dealerAvatar"
                                         class="game-img w-100"
                                         :alt="table.type">
                                    <div class="overlay position-absolute w-100 text-center pt-2 h-100 row mx-0">
                                        <div class="live-game-info w-100 my-auto">
                                            <h6 class="my-2 px-1 live-game-title"> {{table.name}} </h6>
                                            <a class="btn btn-orange playbtn w-75 mx-auto d-none d-lg-block"
                                               @click.prevent.stop="gotoPlayLiveGameView(table.lobbyData)">
                                                ИГРАТЬ
                                            </a>
                                            <p class="text-center">
                                                <span>
                                                    <small>{{["Мин:", table.limits[0].min ,  table.limits[0].currency , ', ',
                                                "Макс:", table.limits[0].max ,  table.limits[0].currency].join("")}}
                                                </small>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="row w-100 mx-0 mt-2 justify-content-center">
                            <div class="col-11 col-lg-6 px-0 mx-2 mt-2 text-right mx-auto text-center">
                                <a class="btn btn-gray white-color w-50" @click="goHome()">Вернутся назад</a>
                            </div>
                        </div>
                    </div>
                </div>
            </b-card>
            <b-card no-body class="col-12 border-0 text-white bg-transparent px-0" v-else>
                <div class="row justify-content-center w-100">
                    <i class="fa fa-spinner fa-spin fa-fw my-5 orange-color"></i>
                </div>
            </b-card>

        </b-modal>

    </div>
</template>

<script>
    import axios from 'axios'
    import Config from '@/config'

    export default {
        name: "LiveGameTableSelector",
        props: ['game', 'restoring', 'isLive', 'provider'],
        data() {
            return {
                lobbyData: null,
                tables: null
            }
        },
        computed: {
            isMobile() {
                return this.$_config.environment.mobile
            }
        },
        mounted() {
            if (this.game) {
                this.getTablesList()
            }
            if (!this.restoring && this.isLive === 1 && this.provider.toLowerCase() === "slotegrator") {
                this.$refs.liveTablesModal.show()
            }
        },
        methods: {
            getTablesList() {
                axios.get(
                    Config.baseUrl + '/Api/getGameLiveTables/' + this.game.system + "/" + this.game.id,
                    {
                        crossdomain: true
                    }
                )
                    .then((resp) => {
                        if (resp.data) {
                            let answer = resp.data.data
                            if (typeof answer != "undefined") {
                                this.tables = answer.tables
                            }
                        }
                    }).catch(err => {
                    console.log(err.message)
                })

            },
            gotoPlayLiveGameView(lobbyData) {
                this.$emit('lobbyDataSelected', lobbyData)
                this.$root.$emit('bv::hide::modal', 'liveTablesModal')
                return false
            },
            goHome() {
                this.$router.push({path: '/'})
            }
        }
    }
</script>

<style scoped>
    .live-game-item {
        cursor: pointer;
    }

    .playbtn,
    .live-game-title {
        font-size: 14px;
    }

    .game-img {
        height: 200px;
        border-radius: 8px;
        -o-object-fit: cover;
        object-fit: contain;
    }

    .overlay {
        top: 0;
        left: 0;
        width: 200px;
        background-color: rgba(0, 0, 0, 0.4);
        background-image: url("../../../public/images/border-bg.png");
        background-size: 100% 100%;
        border-radius: 8px;
    }
</style>

