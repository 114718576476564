<template>
    <div class="col-12 px-0" v-if="tab"
         v-lazy-container="{ selector: 'img', loading: require('@/../public/images/icons/game-images-loader.svg') }">
        <div class="row justify-content-between games-list-row no-gutters mx-0">
            <div class="game-item-col col-6" v-for="(game, i) in tab.games" :key="'idx'+i"
                 v-bind:class="[game.system=== 'slotegrator' ? 'col-md-3' : 'col-md-2']">
                <GameItemPreview :game="game"></GameItemPreview>
            </div>
        </div>
    </div>
</template>

<script>
    import GameItemPreview from "./GameItemPreview";

    export default {
        props: ['tab'],
        name: "GameTabTemplate",
        components: {
            GameItemPreview
        }
    }
</script>
